export const environment = {
  production: false,
  url: 'https://dev.keytocheck.com',
  api: {
    url: 'https://apidev.keytocheck.com',
  },
  help_slider: 'https://www.canva.com/design/DAFT53ysO2g/zXEWJoDVt4t1l4OG5j69uA/view',
  i18n: {
    url: 'https://api.i18n.dev.mobilio.mobi/i18n/',
    namespace: '/keytocheck-prod'
  },
  langs: [
    'en',
    'fr'
  ],
  languages: {
    en: 'English',
    fr: 'Français'
  },
  wording: {
    edl: "WeCheck",
    checkin: "AutoCheck",
    cleancheck: "CleanCheck"
  },
  payment_methods: [
    "Visa",
    "MasterCard",
    "American Express"
  ],
  stripe: {
    public_key: "pk_test_51LGl9jLQwNt3UD4kw9dQMSRgsYfWjHcLUSf66aPJDidQWvgYi8QdAtnEB8Gb3MRRkj1QL2a5mhalBlpUBvkshw2Q00nooeg7nc"
  },
  firebase: {
    apiKey: "AIzaSyBiLgzCGU8tpO-f9u9EOS26gcOYbpgWvz8",
    authDomain: "keytocheck-b87da.firebaseapp.com",
    projectId: "keytocheck-b87da",
    storageBucket: "keytocheck-b87da.appspot.com",
    messagingSenderId: "846062612043",
    appId: "1:846062612043:web:41aec15d035b8f091967ce",
    measurementId: "G-5SS3500CJZ"
  },
  adobe_pdf_viewer: {
    client_id: '84f6002f5d6142a9a2e197cd92da87e5',
    local_client_id: '2e44f95fde8a47e08ef05831f0d7a692',
  },
  version: '607839f0ee4cc4fcb5162491181641c484de009f'
};
